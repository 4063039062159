import React from "react"
import LayoutCourse from "../../../../layouts/course"
import Seo from "../../../../components/seo"
import Heading from "../../../../components/heading"
import Stack from "../../../../components/stack"
import Box from "../../../../components/box"
import CourseFooterNext from "../../../../components/course-footer-next"
import Paragraph from "../../../../components/paragraph"
import { getCourseNavigation } from "../../../../store/courses"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Zum nächsten Kapitel"
          to="/kurse/reiseerinnerung/02-dichterwerkstatt"
        />
      }
      navigation={navigation}
    >
      <Seo title="Zusammenfassung" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h2" level={2}>
              Zusammenfassung
            </Heading>
          </Stack>
          <Paragraph>
            In diesem Kapitel hast du Hölderlins Gedicht ›Andenken‹ auf seine
            geografischen Bezüge und biografischen Hintergründe ausgelotet. Die
            vielen konkreten Ortsbezüge verwiesen darauf, dass Hölderlin die im
            Gedicht beschriebene Landschaft in Südfrankreich ziemlich gut
            kannte. Zwar hatte er nicht die Möglichkeit, die alle Schauplätze
            seiner Werke selbst zu bereisen, doch legte er Wert auf geografische
            Genauigkeit und arbeitete dafür mit Landkarten und Reiseführern. Die
            Gegend um Bordeaux, an die sich das lyrische Ich des Gedichts
            ›Andenken‹ zurückerinnert, hat er als Hauslehrer in Frankreich
            allerdings sogar selbst kennengelernt.
          </Paragraph>
          <Paragraph>
            Die Briefe, die er vor und während seiner Winterwanderung nach
            Bordeaux verfasste, ließen die mit der Reise verbundenen Gefahren
            und Abenteuer erahnen, klangen jedoch zunächst sehr zuversichtlich.
            Umso überraschender ist, dass Hölderlin in Bordeaux nur 3 1/2 Monate
            blieb.
          </Paragraph>
          <Paragraph>
            Was sich Hölderlin von seiner Reise nach Frankreich versprach, hast
            du zuletzt selbst noch einmal zusammengetragen und zu einer
            Werbeanzeige für ein Work & Travel-Programm umgeschrieben. Das
            Gedicht ›Andenken‹ entstand gut ein Jahr nach Hölderlins Aufenthalt
            in Frankreich.
          </Paragraph>
          <Paragraph>
            Im nächsten Kapitel sehen wir uns an, wie Hölderlin im Manuskript
            daran gearbeitet hat.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
